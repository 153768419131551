<template>
  <div class="about">
    <div class="titles azjgs">
      <div>
        操作类型：
        <el-select v-model="operates" placeholder="全部" clearable>
          <el-option :value="item.id" v-for="(item, index) in opArr" :key="index" :label="item.name"></el-option>
        </el-select>
      </div>
      <span>
        <el-button v-if="$has('viewMgrLog')" type="success" size="mini" icon="el-icon-search" @click="searchs(1, pagesize)">查 询</el-button>
        <el-button v-if="$has('viewMgrLog')" type="info" size="mini" icon="el-icon-refresh-right" @click="reserts">重 置</el-button>
      </span>
    </div>
    <div class="cont">
      <el-table :data="tableData" height="calc(100% - 40px)">
        <el-table-column prop="userName" label="用户名" show-overflow-tooltip></el-table-column>
        <el-table-column prop="operateType" label="操作类型">
          <template slot-scope="{ row }">{{ opArr[row.operateType].name }}</template>
        </el-table-column>
        <el-table-column prop="operateContents" label="操作内容" show-overflow-tooltip></el-table-column>
        <el-table-column prop="ipAdd" label="操作IP" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="logType" label="日志类型"></el-table-column> -->
        <el-table-column prop="costTime" label="耗时(ms)"></el-table-column>
        <el-table-column prop="createDt" label="创建时间" width="160"></el-table-column>
        <div slot="empty" class="empty">
          <img src="../../public/home/noData.svg" width="320" alt="" />
          <span>暂无数据</span>
        </div>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currpage" :page-sizes="[10, 20, 100, 500, 1000, 5000, 10000]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="totals"> </el-pagination>
      <ToTop />
    </div>
  </div>
</template>
<script>
import { roleLogsQueryAll } from '@/util/http'
import ToTop from '@/components/toTop/toTop.vue'
export default {
  components: { ToTop },
  data() {
    return {
      currpage: 1,
      pagesize: 10,
      totals: 0,
      tableData: [], //数据
      operates: '', //操作类型
      opArr: [
        { id: '', name: '全部' },
        { id: 1, name: '查询' },
        { id: 2, name: '添加' },
        { id: 3, name: '更新' },
        { id: 4, name: '删除' },
        { id: 5, name: '导入' },
        { id: 6, name: '导出' },
        { id: 7, name: '禁用' },
      ],
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.pagesize = val
      this.searchs(this.currpage, val)
    },
    handleCurrentChange(val) {
      this.currpage = val
      this.searchs(val, this.pagesize)
    },
    // 查询
    async searchs(currpage, pagesize) {
      const loading = this.$loading({
        lock: true,
        text: '拼命加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      })
      this.currpage = currpage
      let params = {
        pageNo: currpage,
        pageSize: pagesize,
        operateType: this.operates,
      }
      await roleLogsQueryAll(params)
        .then((data) => {
          this.tableData = data.result.records
          this.totals = data.result.total
        })
        .catch(() => {})
      loading.close()
    },
    // 重置
    reserts() {
      this.currpage = 1
      this.pagesize = 10
      this.operates = ''
      this.searchs(1, 10)
    },
  },
  mounted() {
    this.searchs(1, 10)
  },
}
</script>
<style src="../../public/css/search.less" lang="less" scoped></style>
<style lang="less" scoped>
.cont {
  width: 100%;
}
@media screen and (max-width: 1000px) {
  .titles {
    height: 42px;
    > span {
      top: 142px;
    }
  }
}
</style>
