<template>
  <el-backtop target=".el-table__body-wrapper" :visibility-height="100" :bottom="75">
    <div
      style="{
            height: 100%;
            background-color: #1989fa;
            border-radius: 50%;
            box-shadow: rgb(16 0 0 / 41%) 0px 12px 6px 0px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            width: 100%;
            }"
    >
      <i class="el-icon-top"></i>
    </div>
  </el-backtop>
</template>

<script>
export default {
  methods: {
    handleResize() {
      let text = 0
      // ChannelHistory页多减一行文字高度
      if (this.$route.path == '/ChannelHistory') {
        text = document.getElementsByClassName('proText')[0].offsetHeight
      }
      let doc = document.getElementsByClassName('titles')[0]
      if (doc) {
        let titleHeight = doc.offsetHeight
        document.getElementsByClassName('cont')[0].style.height = `calc(100% - ${titleHeight}px - ${text}px)`
      }
    },
  },
  mounted() {
    this.handleResize()
    // 监听窗口发生变化,动态设置表格高度
    window.addEventListener('resize', this.handleResize)
  },
}
</script>

<style></style>
